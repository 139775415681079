import { connect } from 'react-redux'
import cx from 'classnames'
import React from 'react'
import ReactGA from 'react-ga'
import PropTypes from 'prop-types'
import {
  Navbar,
  Nav,
  NavItem,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap'
import gravatar from 'gravatar'

import { logoutUser } from '../../actions/auth'
import { fetchCustomers, clearCustomers } from '../../actions/customer'
import s from './Header.module.scss'

class Header extends React.Component {
  static propTypes = {
    sidebarToggle: PropTypes.func,
    dispatch: PropTypes.func.isRequired,
  }

  static defaultProps = {
    sidebarToggle: () => {},
  }

  state = { 
    isOpen: false,
    isCustomerOpen: false,
    name: localStorage.getItem('name') || '',
    photo: gravatar.url(localStorage.getItem('email') || '', {s: '40', d: 'mp'}),
    customerName: '',
    customerPhoto: gravatar.url('', {s: '40', d: 'blank'})
  }

  componentDidMount() {
    if (localStorage.getItem('email').endsWith('@snowrise.net')) {
      this.props.dispatch(
        fetchCustomers()
      )
    } else {
      this.props.dispatch(
        clearCustomers()
      )
    }
  }

  componentDidUpdate(prevProps) {
    const customerId = parseInt(localStorage.getItem('customerId'))
    for (let i in this.props.customers) {
      const customer = this.props.customers[i]
      if (customer.id == customerId && customer.name != this.state.customerName) {
        this.setState({
          customerName: customer.name,
        })
      }
    }
  }

  toggleDropdown = () => {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen,
    }))
  }

  toggleCustomerDropdown = () => {
    this.setState(prevState => ({
      isCustomerOpen: !prevState.isCustomerOpen,
    }))
  }

  handleCustomerSelect = (event) => {
    const id = parseInt(event.target.id)
    console.log(id)
    for (let i in this.props.customers) {
      let customer = this.props.customers[i]
      if (customer.id === id) {
        this.setCustomer(customer)
      }
    }
  }
  
  setCustomer = (customer) => {
    ReactGA.event({
      category: 'Admin',
      action: 'Select Customer',
      label: customer.name,
    })
    console.log(customer)
    this.setState({
      customerName: customer.name,
    })
    localStorage.setItem('customerId', customer.id)
    document.location.reload()
  }

  doLogout = () => {
    ReactGA.event({
      category: 'Navigation',
      action: 'Select Menu',
      label: 'Logout',
    })
    this.props.dispatch(logoutUser())
  }

  render() {
    const {customers} = this.props
    const {isOpen, isCustomerOpen} = this.state
    return (
      <Navbar className={s.root}>
        <Nav>
          <NavItem
            className={cx('visible-xs mr-4 d-sm-up-none', s.headerIcon, s.sidebarToggler)}
            href="#"
            onClick={this.props.sidebarToggle}
          >
            <i className="fa fa-bars fa-2x text-muted" />
          </NavItem>
          {/* <NavItem>
            <InputGroup>
              <Input placeholder="Search for..." />
              <InputGroupAddon addonType="append" className="px-2">
                <i className="fa fa-search" />
              </InputGroupAddon>
            </InputGroup>
          </NavItem> */}
        </Nav>
        <Nav className="ml-auto">
          {/* <NavItem className={cx('', s.headerIcon)}>
            <Button>
              <Icon glyph="mail"/>
              <span>8</span>
            </Button>
          </NavItem> */}
          {/* <NavItem className={cx('', s.headerIcon)}>
            <Button>
              <Icon glyph="notification"/>
              <span>13</span>
            </Button>
          </NavItem> */}
          {/* <NavItem className={cx('', s.headerIcon)}>
            <Button>
              <Icon glyph="settings"/>
            </Button>
          </NavItem> */}
          <Dropdown hidden={customers.length == 0} isOpen={isCustomerOpen} toggle={this.toggleCustomerDropdown}>
            <DropdownToggle nav>
              <img className={cx('rounded-circle mr-sm', s.adminPhoto)} src={this.state.customerPhoto} alt="customer" />
              <span className="text-body">{this.state.customerName}</span>
              <i className={cx('fa fa-angle-down ml-sm', s.arrow, {[s.arrowActive]: isCustomerOpen})} />
            </DropdownToggle>
            <DropdownMenu style={{width: '100%'}}>
              {customers.map(customer => (
                <DropdownItem id={customer.id} key={customer.id} onClick={this.handleCustomerSelect} style={{fontSize: "1.2em"}}>{customer.name}</DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
          <Dropdown isOpen={isOpen} toggle={this.toggleDropdown}>
            <DropdownToggle nav>
              <img className={cx('rounded-circle mr-sm', s.adminPhoto)} src={this.state.photo} alt="profile" />
              <span className="text-body">{this.state.name}</span>
              <i className={cx('fa fa-angle-down ml-sm', s.arrow, {[s.arrowActive]: isOpen})} />
            </DropdownToggle>
            <DropdownMenu style={{width: '100%'}}>
              {/* <DropdownItem>
                <NavLink to="/app/posts">Posts</NavLink>
              </DropdownItem>
              <DropdownItem>
                <NavLink to="/app/profile">Profile</NavLink>
              </DropdownItem> */}
              <DropdownItem onClick={this.doLogout}>
                Logout
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </Nav>
      </Navbar>
    )
  }
}

function mapStateToProps(state) {
  return {
    init: state.runtime.initialNow,
    customers: state.customer.customers,
  }
}

export default connect(mapStateToProps)(Header)
