import { combineReducers } from 'redux'
import auth from './auth'
import runtime from './runtime'
import navigation from './navigation'
import dashboard from './dashboard'
import customer from './customer'

export default combineReducers({
  auth,
  runtime,
  navigation,
  dashboard,
  customer,
})
