import React, {Component} from 'react'
import ReactGA from 'react-ga'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Iframe from 'react-iframe'
import {
  ButtonDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from 'reactstrap'

import { fetchDashboards } from '../../actions/dashboard'
import s from './Dashboard.module.scss'

const { SisenseFrame, enums } = window['sisense.embed'];

class Dashboard extends Component {
  /* eslint-disable */
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    errorMessage: PropTypes.string,
    dashboards: PropTypes.array,
  }
  /* eslint-enable */

  static defaultProps = {
    errorMessage: null,
    dashboards: [],
    redirect: null,
  }

  state = {
    isDropdownOpened: false,
    dashboardUrl: "",
    dashboardSlug: "",
    dashboardTitle: "Loading...",
    dashboardHeight: "0px",
    isSSOReady: false,
  }

  componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.hash)
    this.props.dispatch(
      fetchDashboards()
    )
  }

  componentDidUpdate(prevProps) {
    if (this.state.dashboardUrl === "" && this.state.dashboardSlug === "") {
      if (this.props.dashboards.length > 0) {
        const dashboard = this.props.dashboards[0]
        this.showDashboard(dashboard)
      } else if (this.state.dashboardTitle !== "No dashboards") {
        this.setState({
          dashboardTitle: "No dashboards",
        })
      }
    }

    if (this.state.dashboardUrl === "" && this.state.isSSOReady) {
      // Generate configuration
      const sisenseFrame = new SisenseFrame({
          url: 'https://sisense.snowrise.net',
          dashboard: this.state.dashboardSlug,
          settings: {
              showToolbar: false,
              showLeftPane: false,
              showRightPane: true,
          },
          element: document.getElementsByClassName('dashboard')[0]
      });

      // Apply configuration to the existing iFrame
      sisenseFrame.render().then(() => {
          console.log("Sisense iFrame ready!");
      });
    }
  }

  formatDate = (str) => {
    return str.replace(/,.*$/,"")
  }

  toggleDropdown = () => {
    this.setState(prevState => ({
      isDropdownOpened: !prevState.isDropdownOpened,
    }))
  }

  handleSelect = (event) => {
    const slug = event.target.id
    console.log(slug)
    for (let i in this.props.dashboards) {
      let dashboard = this.props.dashboards[i]
      if (dashboard.slug === slug) {
        this.showDashboard(dashboard)
      }
    }
  }

  showDashboard = (dashboard) => {
    ReactGA.event({
      category: 'Navigation',
      action: 'Select Dashboard',
      label: dashboard.title,
    })
    console.log(dashboard)

    this.setState({
      dashboardUrl: dashboard.url,
      dashboardSlug: dashboard.slug,
      dashboardTitle: dashboard.title,
      dashboardHeight: window.innerHeight - 240
    })
  }

  delay() {
    setTimeout(() => {
      this.setState({isSSOReady: true})
    }, 5000)
  }

  render() {
    const { dashboards } = this.props
    var iframe = ''
    if (this.state.dashboardUrl === "") {
      // Sisense
      iframe = (
        <Iframe
          width="100%"
          height={this.state.dashboardHeight}
          id="sisense-dashboard"
          className="dashboard"
          display="initial"
          position="relative"
          frameBorder="0"
          hidden={this.state.dashboardUrl === '' || !this.state.isSSOReady}
        />
      )
    } else {
      // Chartio
      iframe = (
        <Iframe url={this.state.dashboardUrl}
          width="100%"
          height={this.state.dashboardHeight}
          id="dashboard"
          className="dashboard"
          display="initial"
          position="relative"
          frameBorder="0"
          hidden={this.state.dashboardUrl !== ''}
        />
      )
    }

    var iframeSSO = ''
    if (this.props.redirect && !this.state.isSSOReady) {
      console.log(this.props.redirect)
      iframeSSO = (
        <Iframe
          width="0"
          height="0"
          hidden="true"
          url={this.props.redirect}
          onLoad={this.delay()}
        />
      )
    }

    return (
      <div className={s.root}>
          <ButtonDropdown style={{marginTop: "-20px", marginBottom: "-10px"}} isOpen={this.state.isDropdownOpened} toggle={this.toggleDropdown}>
            <DropdownToggle color="default" style={{fontSize: "1.3em"}} caret>
             {this.state.dashboardTitle}
            </DropdownToggle>
            <DropdownMenu>
              {dashboards.map(dashboard => (
                <DropdownItem id={dashboard.slug} key={dashboard.slug} onClick={this.handleSelect} style={{fontSize: "1.2em"}}>{dashboard.title}</DropdownItem>
              ))}
            </DropdownMenu>
          </ButtonDropdown>
          <div style={{paddingTop: "20px"}}>
            {iframe}
          </div>
          {iframeSSO}
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    errorMessage: state.dashboard.errorMessage,
    dashboards: state.dashboard.dashboards,
    redirect: state.dashboard.redirect
  }
}

export default connect(mapStateToProps)(Dashboard)
