import React from 'react'
import ReactGA from 'react-ga'
import PropTypes from 'prop-types'

class ErrorPage extends React.Component {
  static propTypes = {
    error: PropTypes.shape({
      name: PropTypes.string.isRequired,
      message: PropTypes.string.isRequired,
      stack: PropTypes.string.isRequired,
    }).isRequired,
  }
  
  componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.hash)
  }

  render() {
    return (
      <div>
        <h1>Error</h1>
        <p>Sorry, a critical error occurred on this page.</p>
      </div>
    )
  }
}

export default ErrorPage
