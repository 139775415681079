import {
  FETCH_DASHBOARDS_REQUEST,
  FETCH_DASHBOARDS_SUCCESS,
  FETCH_DASHBOARDS_FAILURE,
} from '../actions/dashboard'

export default function dashboard(
  state = {
    dashboards: [],
    redirect: null,
  },
  action,
) {
  switch (action.type) {
    case FETCH_DASHBOARDS_REQUEST:
      return Object.assign({}, state, {
      })
    case FETCH_DASHBOARDS_SUCCESS:
      return Object.assign({}, state, {
        dashboards: action.dashboards,
        redirect: action.redirect,
      })
    case FETCH_DASHBOARDS_FAILURE:
      return Object.assign({}, state, {
        message: 'Something did not work. Please try again later.',
      })
    default:
      return state
  }
}
