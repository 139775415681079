import appConfig from '../config'

export const FETCH_DASHBOARDS_REQUEST = 'FETCH_DASHBOARDS_REQUEST'
export const FETCH_DASHBOARDS_SUCCESS = 'FETCH_DASHBOARDS_SUCCESS'
export const FETCH_DASHBOARDS_FAILURE = 'FETCH_DASHBOARDS_FAILURE'

function requestFetchDashboards() {
  return {
    type: FETCH_DASHBOARDS_REQUEST,
  }
}

function fetchDashboardsSuccess(data) {
  return {
    type: FETCH_DASHBOARDS_SUCCESS,
    dashboards: data.dashboards,
    redirect: data.redirect,
  }
}

function fetchDashboardsError(message) {
  return {
    type: FETCH_DASHBOARDS_FAILURE,
    message,
  }
}

export function fetchDashboards() {
  const config = {
    method: 'get',
    //mode: 'no-cors',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Authorization': localStorage.getItem('id_token'),
    },
  }

  return dispatch => {
    dispatch(requestFetchDashboards())
    const url = `${appConfig.apiUrl}/dashboard?customerId=${localStorage.getItem('customerId')}`
    return fetch(url, config)
      .then(response => response.json().then(data => ({ data, response })))
      .then(({ data, response }) => {
        if (!response.ok) {
          // If there was a problem dispatch error
          dispatch(fetchDashboardsError(data.message))
          return Promise.reject(data)
        }
        // Dispatch the success action
        dispatch(fetchDashboardsSuccess(data))
        return Promise.resolve(data)
      })
      .catch(err => console.error('Error: ', err))
    }
}
