import React from 'react'
import ReactGA from 'react-ga'
import PropTypes from 'prop-types'
import { withRouter, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  Alert,
  Button,
  FormGroup,
  Input,
  Row,
  Col,
  Form
} from 'reactstrap'
import s from './Login.module.scss'
import Widget from '../../components/Widget'
import Footer from "../../components/Footer"
import { loginUser } from '../../actions/auth'
import jwt from 'jsonwebtoken'
import config from '../../config'
import md5 from 'md5'
import Icon from '../../components/Icon'

class Login extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    isAuthenticated: false,
    isFetching: false,
    location: PropTypes.any, // eslint-disable-line
    errorMessage: PropTypes.string,
    name: PropTypes.string,
  }

  static defaultProps = {
    isAuthenticated: false,
    isFetching: false,
    location: {},
    errorMessage: null,
  }

  static isAuthenticated(token) {
    // We check if app runs with backend mode
    if (!config.isBackend && token) return true
    if (!token) return
    const date = new Date().getTime() / 1000
    const data = jwt.decode(token)
    return date < data.exp
}

  constructor(props) {
    super(props)

    this.state = {
      email: '',
      password: '',
    }
  }

  componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.hash)
  }

  changeEmail = (event) => {
    this.setState({email: event.target.value})
  }

  changePassword = (event) => {
    this.setState({password: event.target.value})
  }

  doLogin = (e) => {
    this.props.dispatch(
      loginUser({
        email: this.state.email,
        password: md5(this.state.password),
      }),
    )
    e.preventDefault()
  }

  render() {

    const {from} = this.props.location.state || {
      from: {pathname: '/app'},
    }

    if (this.props.isAuthenticated) {
      // cant access login page while logged in
      return <Redirect to={from} />
    }

    return (
      <div className={s.root}>
      <Row>
        <Col xs={{size: 10, offset: 1}} sm={{size: 6, offset: 3}} lg={{size:4, offset: 4}}>
          {/* <p className="text-center">Snowrise Dashboard</p> */}
          <Widget className={s.widget}>
            {/* <h4 className="mt-0 text-center">Snowrise Dashboard</h4> */}
            <header className={s.logo}>
              <Icon glyph="logo" />
            </header>
            <p className="fs-sm text-muted">
              Please sign in using the registered email address and password provided for your account
            </p>
            <Form className="mt" onSubmit={this.doLogin}>
              {this.props.errorMessage && (
                <Alert size="sm" color="danger">
                  {this.props.errorMessage}
                </Alert>
              )}
              <FormGroup className="form-group">
                <Input
                  className="no-border"
                  value={this.state.email}
                  onChange={this.changeEmail}
                  type="email"
                  required
                  name="email"
                  placeholder="Email"
                />
              </FormGroup>
              <FormGroup>
                <Input
                  className="no-border"
                  value={this.state.password}
                  onChange={this.changePassword}
                  type="password"
                  required
                  name="password"
                  placeholder="Password"
                />
              </FormGroup>
              <div className="d-flex justify-content-between align-items-center">
                <a href="mailto:jim@snowrise.net?subject=Dashboard Login" className="fs-sm">Trouble signing in?</a> {/* eslint-disable-line */}
                <div>
                  <Button color="default" size="sm" hidden="true">
                    Create an account
                  </Button>
                  <Button color="success" size="sm" type="submit">
                    {this.props.isFetching ? 'Loading...' : 'Login'}
                  </Button>
                </div>
              </div>
            </Form>
          </Widget>
        </Col>
      </Row>
      <Footer className="text-center" />
      </div>
    )
  }
}

function mapStateToProps(state) {
    return {
        isFetching: state.auth.isFetching,
        isAuthenticated: state.auth.isAuthenticated,
        errorMessage: state.auth.errorMessage,
    }
}

export default withRouter(connect(mapStateToProps)(Login))
