import {
    FETCH_CUSTOMERS_REQUEST,
    FETCH_CUSTOMERS_SUCCESS,
    FETCH_CUSTOMERS_FAILURE,
    CLEAR_CUSTOMERS_REQUEST,
  } from '../actions/customer'
  
  export default function customer(
    state = {
      customers: [],
    },
    action,
  ) {
    switch (action.type) {
      case FETCH_CUSTOMERS_REQUEST:
        return Object.assign({}, state, {
        })
      case FETCH_CUSTOMERS_SUCCESS:
        return Object.assign({}, state, {
          customers: action.customers,
        })
      case FETCH_CUSTOMERS_FAILURE:
        return Object.assign({}, state, {
          message: 'Something did not work. Please try again later.',
        })
      case CLEAR_CUSTOMERS_REQUEST:
        return Object.assign({}, state, {
          customers: action.customers,
        })
      default:
        return state
    }
  }
  