import React from 'react'
import { connect } from 'react-redux'
import { Switch, Route, Redirect } from 'react-router'
import { HashRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import ReactGA from 'react-ga'

import ErrorPage from '../pages/error'

import '../styles/theme.scss'
import LayoutComponent from '../components/Layout'
//import DocumentationLayoutComponent from '../documentation/DocumentationLayout'
import Login from '../pages/login'
import { logoutUser } from '../actions/auth'

ReactGA.initialize('UA-100201802-2', {
  debug: true,
  gaOptions: {
    siteSpeedSampleRate: 100,
  },
})

const PrivateRoute = ({dispatch, component, ...rest }) => {
    if (!Login.isAuthenticated(localStorage.getItem('id_token'))) {
        dispatch(logoutUser())
        return (<Redirect to="/login"/>)
    } else {
        return ( // eslint-disable-line
            <Route {...rest} render={props => (React.createElement(component, props))}/>
        )
    }
}

const CloseButton = ({closeToast}) => <i onClick={closeToast} className="la la-close notifications-close"/>

class App extends React.PureComponent {
  render() {
    return (
        <div>
            <ToastContainer
                autoClose={5000}
                hideProgressBar
                closeButton={<CloseButton/>}
            />
            <HashRouter>
                <Switch>
                    <Route path="/" exact render={() => <Redirect to="/app/dashboard"/>}/>
                    <Route path="/app" exact render={() => <Redirect to="/app/dashboard"/>}/>
                    <PrivateRoute path="/app" dispatch={this.props.dispatch} component={LayoutComponent}/>
                    <Route path="/login" exact component={Login}/>
                    <Route path="/error" exact component={ErrorPage}/>
                </Switch>
            </HashRouter>
        </div>

    )
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
})

export default connect(mapStateToProps)(App)
