import appConfig from '../config'

export const FETCH_CUSTOMERS_REQUEST = 'FETCH_CUSTOMERS_REQUEST'
export const FETCH_CUSTOMERS_SUCCESS = 'FETCH_CUSTOMERS_SUCCESS'
export const FETCH_CUSTOMERS_FAILURE = 'FETCH_CUSTOMERS_FAILURE'
export const CLEAR_CUSTOMERS_REQUEST = 'CLEAR_CUSTOMERS_REQUEST'

function requestFetchCustomers() {
  return {
    type: FETCH_CUSTOMERS_REQUEST,
  }
}

function fetchCustomersSuccess(data) {
  return {
    type: FETCH_CUSTOMERS_SUCCESS,
    customers: data.customers,
  }
}

function fetchCustomersError(message) {
  return {
    type: FETCH_CUSTOMERS_FAILURE,
    message,
  }
}

function requestClearCustomers() {
  return {
    type: CLEAR_CUSTOMERS_REQUEST,
    customers: [],
  }
}

export function fetchCustomers() {
  const config = {
    method: 'get',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Authorization': localStorage.getItem('id_token'),
    },
  }
  
  return dispatch => {
    dispatch(requestFetchCustomers())
    const url = `${appConfig.apiUrl}/customer`
    return fetch(url, config)
      .then(response => response.json().then(data => ({ data, response })))
      .then(({ data, response }) => {
        if (!response.ok) {
          // If there was a problem dispatch error
          dispatch(fetchCustomersError(data.message))
          return Promise.reject(data)
        }
        // Dispatch the success action
        dispatch(fetchCustomersSuccess(data))
        return Promise.resolve(data)
      })
      .catch(err => console.error('Error: ', err))
    }
}

export function clearCustomers() {
  return dispatch => {
    dispatch(requestClearCustomers())
  }
}