import React from 'react'
import ReactGA from 'react-ga'

import s from './NotFound.module.scss'

class NotFound extends React.Component {

  componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.hash)
  }

  render() {
    return (
      <div className={s.root}>
        <h1 className={s.title}>404</h1>
        <p>Sorry, the page you were trying to view does not exist.</p>
      </div>
    )
  }
}

export default NotFound