import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import s from './Footer.module.scss'

class Footer extends React.Component {
  static propTypes = {
    className: PropTypes.string,
  }

  static defaultProps = {
    className: '',
  }

  render() {
    return (
      <footer className={cx(s.root, this.props.className)}>
        <div className={s.container}>
          <span>©&nbsp;2020&nbsp;Snowrise</span>
          <span className={s.spacer}>·</span>
          <a href="https://snowrise.net/terms-of-service/">Terms of Service</a>
          <span className={s.spacer}>·</span>
          <a href="https://snowrise.net/privacy-policy/">Privacy Policy</a>
          <span className={s.spacer}>·</span>
          <a href="mailto:jim@snowrise.net?subject=Dashboard Contact">Contact Us</a>
        </div>
      </footer>
    )
  }
}

export default Footer
