import ReactGA from 'react-ga'
import appConfig from '../config'

export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE'

function requestLogin(creds) {
  return {
    type: LOGIN_REQUEST,
    isFetching: true,
    isAuthenticated: false,
    creds,
  }
}

export function receiveLogin(data) {
  return {
    type: LOGIN_SUCCESS,
    isFetching: false,
    isAuthenticated: true,
    id_token: data.id_token,
    name: data.user.name,
  }
}

function loginError(message) {
  return {
    type: LOGIN_FAILURE,
    isFetching: false,
    isAuthenticated: false,
    message,
  }
}

function requestLogout() {
  return {
    type: LOGOUT_REQUEST,
    isFetching: true,
    isAuthenticated: true,
  }
}

export function receiveLogout() {
  return {
    type: LOGOUT_SUCCESS,
    isFetching: false,
    isAuthenticated: false,
  }
}

export function logoutUser() {
  return dispatch => {
    dispatch(requestLogout())
    localStorage.removeItem('id_token')
    document.cookie = 'id_token=;expires=Thu, 01 Jan 1970 00:00:01 GMT;'
    document.location='/#/login'
    dispatch(receiveLogout())
  }
}

export function loginUser(creds) {
  const config = {
    method: 'POST',
    //mode: 'no-cors',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    // credentials: 'include',
    body: `email=${encodeURIComponent(creds.email)}&password=${encodeURIComponent(creds.password)}`,
  }

  return dispatch => {
    // Dispatch requestLogin to kick off the call to the API
    dispatch(requestLogin(creds))
    const url = `${appConfig.apiUrl}/login`
    return fetch(url, config)
      .then(response => response.json().then(data => ({ data, response })))
      .then(({ data, response }) => {
        if (!response.ok) {
          // If there was a problem dispatch error
          dispatch(loginError(data.message))
          return Promise.reject(data)
        }
        // If login was successful set the token and user in local storage
        localStorage.setItem('id_token', data.id_token)
        localStorage.setItem('name', data.user.name)
        localStorage.setItem('email', data.user.email)
        localStorage.setItem('customerId', data.user.customerId)
        // Configure analytics
        ReactGA.set({ userId: data.user.id })
        ReactGA.set({ dimension1: data.user.id })
        ReactGA.set({ dimension2: data.user.customerId })
        // Dispatch the success action
        dispatch(receiveLogin(data))
        return Promise.resolve(data)
      })
      .catch(err => console.error('Error: ', err))
  }
}
